.material-icons {
  font-size: unset;
}

.material-icons.eapp-icon-mid {
  font-size: 20px;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
}

.material-icons.material-icons-round {
  font-family: 'Material Icons Round';
}

.material-icons.material-icons-sharp {
  font-family: 'Material Icons Sharp';
}

.material-icons.material-icons-two-tone {
  font-family: 'Material Icons Two Tone';
}

.ms-Checkbox-checkbox .material-icons {
  font-size: 20px;
  padding-top: 2px;
}

.ms-Button--hasMenu .ms-Button-menuIcon {
  height: 24px;
  font-size: 24px;
}

.ms-ContextualMenu-icon {
  display: flex;
  align-items: center;
}

.ms-PanelAction-close .ms-Button-icon,
.ms-Dialog-button--close .ms-Button-icon,
.ms-CommandBar-overflowButton .ms-Button-menuIcon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  height: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
